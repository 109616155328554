$('.g-member__slider').owlCarousel({
    loop:false,
    nav:true,
    margin:30,
    dots:false,
    navText: [
        "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
        "</svg>",
        "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
        "</svg>"
    ],

    responsive:{
        0:{
            items:1,
            autoplay:true,
        },
        480:{
            items:2
        },
        809:{
            items:3
        },
        1059:{
            items:4
        }
    }
})

$('.partners-slider').owlCarousel({
    loop:true,
    nav:true,
    margin:30,
    dots:true,
    dotsEach: 1,
    navText: [
        "<svg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M1 0.546875L10 9.54687L19 0.546875' stroke='black'/>"+
        "</svg>",
        "<svg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M1 0.546875L10 9.54687L19 0.546875' stroke='black'/>"+
        "</svg>"
    ],

    responsive:{
        0:{
            items:1,
            nav:false,
            autoplay: true,
        },
        480:{
            items:2,
        },
        809:{
            items:3,
        },
        1180:{
            items:4,
            nav:true,
        }
    }
})

$(".carosel-questions-inner").owlCarousel({
    loop: true,
    nav: true,
    items: 3,
    margin: 45,
    dots: true,
    dotsEach:3,
    slideBy:3,
    navText: [
        "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
        "</svg>",
        "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
        "</svg>"
    ],
    responsive: {
        0: {
            dotsEach: 1,
            items: 1,
            nav: false,
        },
        480: {
            dotsEach:1,
            items: 1,
            nav: false,
        },
        768: {
            items: 3,
            nav: true,
        },
    },
});