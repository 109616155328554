$(document).ready(function () {
    $(".artist-dealer-supporter .list-resources .title-wrapper").on(
        "click",
        function () {
            $(this).toggleClass("rotate");
            $(this).siblings().toggleClass("appear");
        }
    );

    let owlFaq = $(".artist-dealer-supporter .g-question-by-artist .g-question-by-artist__slider.owl-carousel");
    owlFaq.on('changed.owl.carousel',function(property){
        var current = property.item.index;
        var faqUrl = $(property.target).find(".owl-item").eq(current).find(".g-question-by-artist__item > div").attr('class');
       
        $(".artist-dealer-supporter .g-question-by-artist .content-faqs .arrows").attr("href",faqUrl);
    });
});

$(".artist-dealer-supporter .g-member__slider").owlCarousel({
    loop: false,
    nav: true,
    margin: 30,
    dots: false,
    navText: [
        "<div class='nav-btn prev-slide'></div>",
        "<div class='nav-btn next-slide'></div>",
    ],

    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 3,
        },
        1059: {
            items: 4,
        },
    },
});

