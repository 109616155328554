$(document).ready(function () {
    $(".about-us-page #about-us-member-link").on("click", function (e) {
        e.preventDefault();
        $(this).siblings('.description').toggleClass('show-full-text');
        $(this).siblings('#about-us-member-link-less').toggleClass('show');
        $(this).toggleClass('show');
    });

    $(".about-us-page #about-us-member-link-less").on("click", function (e) {
        e.preventDefault();
        $(this).siblings('.description').toggleClass('show-full-text');
        $(this).siblings('#about-us-member-link').toggleClass('show');
        $(this).toggleClass('show');
    });
});
