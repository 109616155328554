$('.faqs-list-slider').owlCarousel({
    loop:false,
    nav:true,
    margin:30,
    dots:true,
    navText: [
        "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
        "</svg>",
        "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
        "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
        "</svg>"
    ],

    responsive:{
        0:{
            items:1,
            autoplay:true,
            nav:false
        },
        480:{
            items:2
        },
        940:{
            items:3
        }
    }
})

$(document).ready(function () {
    var formContact = $('#form-contact');
    $('#submit-mail-form').on('click', function () {
        formContact.parsley();
        formContact.submit();
    });
});