import AOS from 'aos';

function addScrollAnimation(item, type = 'fade-right', timeout = 1000, delay = 0) {
    if ($(item).length > 0) {
        $(item).each(function () {
            $(this).attr({
                'data-aos': type,
                'data-aos-duration': timeout,
                'data-aos-delay': delay
            })
            delay += 200
            if ($(this).hasClass("hide-field")) {
                $(this).hide();
            } else {
                $(this).show();
            }
        })
    }
}

function addAnimationSmall(item, type = 'fade-right', timeout = 1000, delay = 0) {
    if ($(item).length > 0) {
        $(item).each(function () {
            $(this).attr({
                'data-aos': type,
                'data-aos-duration': timeout,
                'data-aos-delay': delay
            })
            delay += 50
            if ($(this).hasClass("hide-field")) {
                $(this).hide();
            } else {
                $(this).show();
            }
        })
    }
}

function addAnimationNoDelay(item, type = 'fade-right', timeout = 1000) {
    if ($(item).length > 0) {
        $(item).each(function () {
            $(this).attr({
                'data-aos': type,
                'data-aos-duration': timeout,
            })
            if ($(this).hasClass("hide-field")) {
                $(this).hide();
            } else {
                $(this).show();
            }
        })
    }
}


let width = $(window).width()

//News article page
addAnimationNoDelay(".news-article-page .text-content:not(.short-subhead) .f-body > :not(ol,ul)", 'fade-up', 1000)
addAnimationNoDelay(".news-article-page .text-content:not(.short-subhead) .f-body li",'fade-up')
addAnimationNoDelay(".news-article-page .news-content .video--iframe",'fade-right')
$(".news-article-page .text-content .f-body h3").attr("data-aos", "fade-right")
$(".news-article-page .text-content:not(.short-subhead) .f-body > :not(ol,ul)").attr("data-aos-delay","500")
$(".news-article-page .news-content .video--iframe").attr("data-aos-delay","500")
$(".news-article-page .text-content:not(.short-subhead) .f-body li").attr("data-aos-delay","500")
// faqs
addAnimationSmall(".faqs .carosel-questions .owl-prev", 'fade-up', 1000, 0)
addAnimationSmall(".faqs .carosel-questions .owl-next", 'fade-up', 1000, 1400)
addScrollAnimation(".faqs .carosel-questions .owl-dots", 'fade-right', 1000, 0)

// Privacy Policy
addAnimationNoDelay(".privacy-policy-page .g-intro__shortdesc .f-body > *", 'fade-right')
$(".privacy-policy-page + .g-footer__acknowledge").attr("data-aos-delay", "500")
addAnimationNoDelay(".terms-conditions-page .g-intro__shortdesc .f-body > *", 'fade-right')
$(".terms-conditions-page + .g-footer__acknowledge").attr("data-aos-delay", "500")

//Reuse section: Image-text, video-text, member list, grid-text section,,..
addScrollAnimation(".g-content__question-and-link .g-content_question-item", 'fade-up')
addAnimationNoDelay(".video-section .title", 'fade-right')
addAnimationNoDelay(".video-section .subtitle p", 'fade-right')
addAnimationNoDelay(".video-section .article-video > *", 'fade-left')
addAnimationNoDelay(".video-section .video-section-button .btn", 'fade-up')
addAnimationNoDelay(".g-frequently-question .faq-item", "fade-up")
addAnimationNoDelay(".o-grid-block:not(.step-list) .o-grid-title", "fade-up")
addAnimationNoDelay(".o-grid-block .o-grid-item:first-child .f-body >*", "fade-right")
addAnimationNoDelay(".o-grid-block:not(.step-list,.check-list) .o-grid-item:last-child .f-body >*", "fade-left")
addAnimationNoDelay(".o-grid-block.step-list .o-grid-title", "fade-right")
addAnimationNoDelay(".o-grid-block.check-list .o-grid-item .f-body ul li", "fade-right")
addAnimationNoDelay(".o-grid-block.step-list .o-grid-item .f-body:has(ol) > :not(ol)", "fade-up")
addScrollAnimation(".o-grid-block.step-list .o-grid-item .f-body ol li",'fade-up',1000,0)
addAnimationNoDelay(".o-grid-block .o-grid-item .btn", "fade-up")
$(".o-grid-block .o-grid-block-inner:not(:has(.btn))").addClass('grid-no-button')


if (width >= 768) {
    addScrollAnimation(".g-frequently-question .faq-item", "fade-up");
}
if(width < 768){
    addAnimationNoDelay("main > :not(.homepage) .video-section .mobile-title", 'fade-right');
    addAnimationNoDelay("main > :not(.homepage) .image-Text-left .title.mobile", 'fade-left');
    addAnimationNoDelay("main > :not(.homepage) .image-Text-right .g-section__image--mobile", 'fade-left');
    addAnimationNoDelay("main > :not(.homepage) .image-Text-right .title.mobile", 'fade-right');
}
addAnimationNoDelay(".o-body-text-block .container > *", 'fade-up')

//Artist-Dealer-Supporter page
addAnimationNoDelay(".artist-dealer-supporter .g-intro__image > *", 'fade-right')
addAnimationNoDelay(".artist-dealer-supporter .g-intro__content .g-intro__title", 'fade-left')
addAnimationNoDelay(".artist-dealer-supporter .g-intro__content .g-intro__shortdesc p", 'fade-left')
addAnimationNoDelay(".artist-dealer-supporter .g-intro__buttons .btn", 'fade-up')
$(".artist-dealer-supporter .image-Text .text-content-right").removeAttr("data-aos data-aos-duration")
$(".artist-dealer-supporter .image-Text .f-body-wrapper").removeAttr("data-aos data-aos-duration")
addAnimationNoDelay(".artist-dealer-supporter .image-Text .text-content-right .f-body > *", 'fade-left')
addAnimationNoDelay(".artist-dealer-supporter .image-Text .f-body-wrapper .f-body > *", 'fade-right')
addAnimationNoDelay(".artist-dealer-supporter .image-Text .g-section__description .custom-link", 'fade-up')
addAnimationNoDelay(".artist-dealer-supporter .section.resources h2", 'fade-right')
addAnimationSmall(".artist-dealer-supporter .section.resources .resources-item > *", 'fade-right')
addAnimationNoDelay(".artist-dealer-supporter .g-artist-list .artist-list-title", 'fade-right')
$('.artist-dealer-supporter .g-artist-list .g-artist-list__slider').on('initialized.owl.carousel translate.owl.carousel', function(){
    addScrollAnimation(".artist-dealer-supporter .g-artist-list .owl-item", 'fade-up')
})
if($(".artist-dealer-supporter > .aos-animate")){
    $(".artist-dealer-supporter").css({"visibility": "visible","transition-delay":"0.5s"});
}

if(width <768){
    addScrollAnimation(".artist-dealer-supporter .o-step__become-member .steps >*",'fade-right')
    $(".artist-dealer-supporter .o-step__become-member .line").attr("data-aos","fade-down")
    addAnimationNoDelay(".artist-dealer-supporter .g-intro .g-intro__image--mobile > *",'fade-right')
}


//Artist Page
addAnimationNoDelay(".artist-page .g-question-by-artist .question-title", 'fade-right')
addAnimationNoDelay(".artist-page .g-question-by-artist .g-question-by-artist__slider", 'fade-up')
addAnimationNoDelay(".artist-page .g-question-by-artist .arrows-long", 'fade-up')
addAnimationNoDelay(".artist-page .section-8 h2:not('.mobile')", 'fade-right')
addAnimationNoDelay(".artist-page .section-8 ul li",'fade-right')
addAnimationNoDelay(".artist-page .section-8 .f-buttons",'fade-up')
$(".artist-page .video-section .container").append($("<span class='line-end'></span>"))
addAnimationNoDelay(".artist-page .video-section .container .line-end",'fade-up')

if (width >= 768) {
    $(".dealer-page .o-step__become-member .steps > *").removeAttr("data-aos data-aos-duration data-aos-delay")
    addScrollAnimation('.dealer-page .o-step__become-member .steps > *', 'fade-right', 1000)
}

//About-us Page
// addAnimationNoDelay(".about-us-page .g-intro .g-intro-left > :not(.g-intro__title)", 'fade-right')
addAnimationNoDelay(".about-us-page .g-intro .g-intro-right .g-intro__shortdesc p", 'fade-left')
// addAnimationNoDelay(".about-us-page .g-intro .g-intro-right .btn-block", 'fade-up')
// $(".about-us-page .image-Text .text-content-right").removeAttr("data-aos data-aos-duration")
// $(".about-us-page .image-Text .f-body-wrapper").removeAttr("data-aos data-aos-duration")
addAnimationNoDelay(".about-us-page .image-Text .text-content-right .f-body > *", 'fade-left')
addAnimationNoDelay(".about-us-page .image-Text .f-body-wrapper .f-body > *", 'fade-right')
$(".about-us-page .iartc-strategic .col-iartc-strategic").removeAttr("data-aos data-aos-duration")
addAnimationSmall(".about-us-page .iartc-strategic .col-iartc-strategic > *", 'fade-right')
$(".about-us-page+.g-content__question-and-link .container").prepend($("<span class='line-end'></span>"))
addAnimationNoDelay(".about-us-page+.g-content__question-and-link .container .line-end",'fade-up')
$(".about-us-page .image-Text .text-content-right").removeAttr("data-aos data-aos-duration")
$(".about-us-page .image-Text .f-body-wrapper").removeAttr("data-aos data-aos-duration")

if(width <768){
    addAnimationNoDelay(".about-us-page .g-intro .g-intro-left .g-intro__title.g-intro-mobile", 'fade-left')
}

// Membership page
addScrollAnimation('.membership .paragraph-content li', 'fade-up')
addAnimationNoDelay('.membership .g-intro-shortdesc > .f-body > *', 'fade-left', 1000)

//Artist Membership page
addScrollAnimation('.membership-application .paragraph-content > *', 'fade-up')
if (width >= 768) {
    $(".membership-application .steps > *").removeAttr("data-aos data-aos-duration data-aos-delay")
    addScrollAnimation('.membership-application .steps > *', 'fade-right', 1000)
}
addAnimationNoDelay('.membership-application .o-grid-block-inner .btn', 'fade-up', 1000)
$(".membership-application .section-2 h2").attr("data-aos", "fade-up").attr("data-aos-duration", "1000").show()
// addAnimationNoDelay('.membership-application .o-grid-block-inner > .o-grid-item:first-child li', 'fade-right', 1000)
// addAnimationNoDelay('.membership-application .o-grid-block-inner > .o-grid-item:last-child li', 'fade-right', 1000)
$(".membership-application .image-Text .g-section__image").removeAttr("data-aos-delay")
$(".membership-application .image-Text .g-section__image+div").removeAttr("data-aos-delay")
$(".membership-application .image-Text .g-section__image--rounded-frame").removeAttr("data-aos-delay")
$(".membership-application .image-Text .g-section__image--rounded-frame+div").removeAttr("data-aos-delay")
$(".membership-application .image-Text .title.desktop").removeAttr("data-aos-delay")
$(".membership-application .image-Text .buttons--container .f-buttons").removeAttr("data-aos-delay")
$(".membership-application .image-Text .f-body-wrapper").removeAttr("data-aos-delay")

$(".membership-application .image-Text .text-content-right").removeAttr("data-aos data-aos-duration")
$(".membership-application .image-Text .f-body-wrapper").removeAttr("data-aos data-aos-duration")
addAnimationNoDelay(".membership-application .image-Text .text-content-right .f-body > *", 'fade-left')
addAnimationNoDelay(".membership-application .image-Text .f-body-wrapper .f-body > *", 'fade-right')

// What is the code page
addAnimationNoDelay('.wit-code .g-intro-shortdesc > .f-body > *', 'fade-left', 1000)
addAnimationNoDelay('.wit-code .o-grid-block-inner .btn', 'fade-up', 1000)
addAnimationNoDelay('.wit-code .section-1 h2', 'fade-right', 1000, 0)
$(".wit-code .section-2 .g-section__description .f-body-wrapper").removeAttr("data-aos data-aos-duration data-aos-delay")
addAnimationNoDelay('.wit-code .section-2 .g-section__description .f-body > *', 'fade-right', 1000)

$(".wit-code .image-Text .text-content-right").removeAttr("data-aos data-aos-duration")
$(".wit-code .image-Text .f-body-wrapper").removeAttr("data-aos data-aos-duration")
addAnimationNoDelay(".wit-code .image-Text .text-content-right .f-body > *", 'fade-left')
addAnimationNoDelay(".wit-code .image-Text .f-body-wrapper .f-body > *", 'fade-right')

//homepage
addScrollAnimation(".partners-slider button.owl-prev", "fade-right", 500, 400);
addScrollAnimation(".partners-slider button.owl-next", "fade-left", 500, 1400);
addScrollAnimation(".partners-slider .owl-dots.disabled", "fade-up", 500, 1600);
addScrollAnimation(".homepage ~ .g-footer__acknowledge", "fade-up", 1000, 2000);
addScrollAnimation(".contact-page ~ .g-footer__acknowledge", "fade-up", 1000, 1000);
addScrollAnimation(".homepage .g-intro__title", "fade-left", 1000, 0);
addScrollAnimation(".homepage .g-intro__image--mobile .image--frame", "fade-left", 1000, 200);
addScrollAnimation(".homepage .g-intro__image--mobile .g-intro__caption", "fade-left", 1000, 400);
addScrollAnimation(".homepage .g-intro__shortdesc", "fade-left", 1000, 600);
if(width <768){
    addScrollAnimation(".homepage .image-Text-right .g-section__image--mobile", "fade-right", 1000, 0);
    addScrollAnimation(".homepage .g-intro__title", "fade-right", 1000, 0);
    addScrollAnimation(".homepage .g-intro__image--mobile .image--frame", "fade-right", 1000, 200);
    addScrollAnimation(".homepage .g-intro__image--mobile .g-intro__caption", "fade-right", 1000, 400);
    addScrollAnimation(".homepage .g-intro__shortdesc", "fade-right", 1000, 600);
}
//Artist application
addAnimationNoDelay('.artist .membership-form-step2 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.artist .membership-form-step3 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.artist .membership-form-step4 .form-wrapper > :not(.action, .modal, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.artist .membership-form-step5 .form-wrapper > :not(script, .action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.artist .membership-form-step6 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.dealer .membership-form-step2 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.dealer .membership-form-step3 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.dealer .membership-form-step4 .form-wrapper > :not(.modal.fade, .validate-message-form, .multisite-memberships, .add-another)', 'fade-up', 1000)
addAnimationNoDelay('.dealer .membership-form-step5 .form-wrapper > :not(script, .action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.dealer .membership-form-step6 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up')
// addAnimationNoDelay('.dealer .membership-form-step7 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up')
addAnimationNoDelay('.dealer .membership-form-step9 .form-description.certify .certify-list > *', 'fade-right')
addAnimationNoDelay('.supporter .membership-form-step2 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up', 1000)
addAnimationNoDelay('.supporter .membership-form-step3 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up')
addAnimationNoDelay('.supporter .membership-form-step4 .form-wrapper > .form-box', 'fade-up', 1000)
addAnimationNoDelay('.supporter .membership-form-step5 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up')
addAnimationNoDelay('.supporter .membership-form-step6 .form-wrapper > :not(.action, .validate-message-form)', 'fade-up')
addAnimationNoDelay('.supporter .membership-form-step7 .form-description.certify ul li', 'fade-right', 1000)
addAnimationNoDelay('.supporter .membership-form-step4 .form-wrapper .organisation > *', 'fade-up', 1000)
addScrollAnimation('.no-longer-member .title h1', 'fade-right', 1000, 0)
addScrollAnimation('.no-longer-member .title p', 'fade-right', 1000, 200)
addScrollAnimation('.no-longer-member .button--back', 'fade-up', 1000, 400)
addScrollAnimation('.no-longer-member .image', 'fade-right', 1000, 600)
addScrollAnimation('.no-longer-member .description', 'fade-right', 1000, 800)

//Selected option
$('.is_apply_individual').on('change', function () {
    if ($(this).val() === 'organisation') {
        $('.supporter .membership-form-step4 .form-wrapper .organisation > *').removeClass('aos-animate')
    } else {
        $('.supporter .membership-form-step4 .form-wrapper .individual > *').removeClass('aos-animate')
    }
    setTimeout(function () {
        AOS.init({
            once: true
        })
    }, 1)
});

$('.membership-form-start #btn-submit-form').on('click', function () {
    setTimeout(function () {
        addAnimationNoDelay('.membership-form-start .parsley-errors-list', 'fade-up', 1000)
        AOS.init({
            once: true
        })
    }, 1)
});

$('#btn-submit-form').on('click', function () {
    setTimeout(function () {
        addAnimationNoDelay('.big-size.show-message + .filled', 'fade-up', 1000)
        if ($('#membership-application-form-id .parsley-error:visible').not('.feedback-field').length > 0) {
            $('#membership-application-form-id .validate-message-form').css('display', 'flex')
            addAnimationNoDelay('#membership-application-form-id .validate-message-form', 'fade-up', 1000)
        }
        AOS.init({
            once: true
        })
    }, 1)
});

