// import {Fancybox} from '@fancyapps/ui/src/Fancybox/Fancybox.js';
import AOS from 'aos';
import './jquery.magnific-popup.min'

$(document).ready(function () {

    // Add class custom
    if ($('.public-profile-wrapper').length) {
        $('body').addClass('public-profile-body');
    }

    // Function show more artwork
    const showMoreArtworks = () => {
        let itemAll = $('.artworks .grid-item').length

        // Show the default max nine images
        $('.artworks .grid-item:lt(9)').show().addClass('show')
        if (itemAll < 10) {
            $(".artworks .artworks-action").hide()
        }

        let itemShow = $('.artworks .show').length
        $('#artworks-paginate').html(`Showing ${itemShow}/${itemAll}`)
        // When the gallery button is clicked
        $("#show-more-artworks").unbind('click').on('click', function (e) {

            // Prevent default behavior
            e.preventDefault()

            // All of the hidden images
            let $hidden = $(".artworks .grid-item:hidden")

            // Show the next nine images
            $($hidden).slice(0, 9).show().addClass('show')

            // If the length of $hidden is nine then hide the button
            if ($hidden.length <= 9) {
                $(this).parent().hide()
            }

            //AOS library init
            setTimeout(function () {
                AOS.init({
                    once: true
                })
            }, 1)

            // Update paginate
            itemShow = $('.artworks .show').length
            $('#artworks-paginate').html(`Showing ${itemShow}/${itemAll}`)
        });
    }
    showMoreArtworks()

    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-with-zoom',
        gallery: {
            enabled: true,
            tCounter: '',
        }
    });

    function moveElementPopup() {
        setTimeout(function () {
            if ($(window).width() > 767) {
                $('.mfp-gallery').addClass('gallery-desktop')
                if ($('.mfp-gallery figcaption .mfp-arrow').length === 0) {
                    $('.mfp-gallery .mfp-arrow').appendTo('.mfp-gallery figcaption')
                }
            } else {
                $('.mfp-gallery').removeClass('gallery-desktop')
                if ($('.mfp-gallery .mfp-figure > .mfp-arrow').length === 0) {
                    $('.mfp-gallery .mfp-arrow').appendTo('.mfp-gallery .mfp-figure')
                }
            }
        }, 1)
    }

    // Move arrow gallery
    $('.popup-gallery a').on('click', function (e) {
        moveElementPopup()
    })

    $(window).resize(function () {
        moveElementPopup()
    })

    // Custom carousel
    $('.public-profile-wrapper .g-member__slider').owlCarousel('destroy');
    $(".public-profile-wrapper .g-member__slider").owlCarousel({
        loop: false,
        nav: true,
        margin: 30,
        dots: false,
        navText: [
            "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
            "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
            "</svg>",
            "<svg width='11' height='20' viewBox='0 0 11 20' fill='none' xmlns='http://www.w3.org/2000/svg'>"+
            "<path d='M0.546875 19L9.54687 10L0.546874 1' stroke='black'/>"+
            "</svg>"
        ],

        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 3,
            },
            1059: {
                items: 4,
            },
        },
    });
})

