import AOS from 'aos';

$(document).ready(function () {

    // Function reload animation when on click show more
    function animationShowMore(){
        $(".button--show-more a").on('click', function (e) {
            setTimeout(function () {
                AOS.init({
                    once: true
                })
            }, 1)
        });
    }
    animationShowMore()
    $(document).ajaxComplete(function () {
        animationShowMore()
    });
});
