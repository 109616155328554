$(function () {
    $("#datepicker").datetimepicker({
        dateFormat: 'dd-mm-y',
        timeFormat: "hh:mmtt",
        timeInput: true,
        showHour: null,
        showMinute: false,
        showButtonPanel: false,
        timeOnlyShowDate: true
    });
});

