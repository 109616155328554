$(document).ready(function () {
    $(".g-footer .g-footer__inner .g-footer_inner-column:nth-child(2) .first-col-text p:nth-child(1)").on("click", function () {
        $(this).toggleClass('rotate');
        $(this).siblings().toggleClass('appear')
    });

    $(".g-footer .g-footer__inner .g-footer_inner-column:nth-child(3) .first-col-text p:nth-child(1)").on("click", function () {
        $(this).toggleClass('rotate');
        $(this).siblings().toggleClass('appear')
    });

    $(".g-footer .g-footer__inner .g-footer_inner-column:nth-child(4) .first-col-text p:nth-child(1)").on("click", function () {
        $(this).toggleClass('rotate');
        $(this).siblings().toggleClass('appear')
    });
});