import AOS from 'aos';
import './owl.carousel.min';
import './jquery3.6.min.js';
import './jquery-ui.min.js';
import './datetime.js';
import './footer.js';
import './artist-page.js';
import './homepage.js';
import 'dm-file-uploader';
import './about-us.js';
import 'jquery-ui/ui/widgets/accordion.js';
import 'jquery-ui/ui/widgets/tabs.js';
import './scrolllt.min';
import './contact-us.js';
import './event.js';
import './public-profile.js';
import './printThis';
import './animation.js';
import './artists-tab.js';
import './search-page';
import './lessmore';
import './jquery.lazy.min';

global.$ = global.jQuery = require('jquery');
require('jquery-modal');
require('jquery-confirm')

$(document).ready(function () {

    // Read more block content
    $('.dolessmore').dolessmore({
        lHeight: 66
    });

    // Refresh AOS when change viewport
    $(document).on('click', '#filter-topics-btn', function (e) {
        if(!$(this).parent().hasClass('open')){
            setTimeout(function () {
                AOS.refresh();
            }, 300)
        }
    })

    // custom scroll to section
    const scrollSection = () => {
        let blockMove = window.location.hash
        let heightHeader = $('.g-header').outerHeight()
        let heightAnnouncement = $('.announcement').outerHeight()
        let heightTab = 0
        if ($('.tabbedPanels > .tabs').length) {
            heightTab = $('.tabbedPanels > .tabs').outerHeight()
        }
        $(".close-btn").on("click", function(){
            heightHeader = heightHeader - heightAnnouncement
        });
        let checkBlock = $(blockMove).length
        $('a[href^="#"]').not('[data-no-scroll]').on('click', function (e) {
            var faqPage = $(this).closest('.faqs');
            if (faqPage.length == 0) {
                $('html, body').animate({
                    scrollTop: $($(this).attr('href')).offset().top - (heightHeader + heightTab + 30)
                }, 600)
                e.stopPropagation()
            }
        });
        if (blockMove && checkBlock > 0) {
            $('html, body').animate({
                scrollTop: $(blockMove).offset().top - (heightHeader + heightTab + 30)
            }, 600)
        }
    }
    setTimeout(function () {
        // Auto scroll the top page
        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        } else {
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            }
        }

        scrollSection()
    }, 0.5)


    //AOS library init

    setTimeout(function () {
        AOS.init({
            offset: 20,
            once: true
        })
    }, 300)

    $(document).ajaxComplete(function () {
        AOS.init({
            once: true
        })
    });
    //Custom select option
    const isSelected = (options) => {
        let result = false;
        options.each(function () {
            let that = $(this);
            if (that.hasClass("selected")) {
                result = true;
                return false;
            }
        });
        return result;
    }

    let customStyleSelect = () => {
        var customSelect = $(".custom-select");
        customSelect.each(function () {
            var thisCustomSelect = $(this),
                options = thisCustomSelect.find("option"),
                firstOptionText = options.first().text();
            let optionSelected = thisCustomSelect.find("option[selected]").text(),
                requiredSelect = thisCustomSelect.find("select").attr("data-parsley-required"),
                hasFeedback = thisCustomSelect.find("select").hasClass('parsley-error');
            if (hasFeedback) {
                var filledErr = $("<ul></ul>", {
                    class: "parsley-errors-list filled"
                });
                filledErr.appendTo(thisCustomSelect)
            }
            var selectedItem = $("<div></div>", {
                class: "selected-item"
            });
            if (thisCustomSelect.find("select").is(":disabled")) {
                selectedItem = $("<div></div>", {
                    class: "selected-item disable"
                });
            } else {
                selectedItem = $("<div></div>", {
                    class: "selected-item enable"
                });
            }

            if (optionSelected) {
                selectedItem.appendTo(thisCustomSelect).text(optionSelected)
            } else {
                selectedItem.appendTo(thisCustomSelect).text(firstOptionText)
            }

            var allItems = $("<div></div>", {
                class: "all-items all-items-hide",
            }).appendTo(thisCustomSelect);

            let i = 1;
            options.each(function () {
                var that = $(this),
                    optionText = that.text(),
                    optionValue = that.val();

                var item = $("<div></div>", {
                    class: "item",
                    value: optionValue,
                    tabindex: i,
                    on: {
                        click: function (e) {
                            var selectedOptionText = that.text();
                            selectedItem
                                .text(selectedOptionText)
                                .removeClass("arrowanim");
                            allItems.addClass("all-items-hide");

                            // Add selected for item
                            $(this).parent().find(".item").removeClass("selected");
                            if (!$(this).hasClass("selected")) {
                                $(this).addClass("selected");
                            }

                            // Add validate for form selected
                            let isValue = $(this).attr("value");
                            if (requiredSelect !== undefined) {
                                if (!isValue) {
                                    $(this).parents(".custom-select").find(".selected-item").removeClass("parsley-success");
                                    $(this).parents(".custom-select").find(".selected-item").addClass("parsley-error");
                                } else {
                                    $(this).parents(".custom-select").find(".selected-item").removeClass("parsley-error");
                                    $(this).parents(".custom-select").find(".selected-item").addClass("parsley-success");
                                }
                            }

                            // Trigger change, focus and change value for select default
                            $(this).parents(".custom-select").find("select").trigger("focus")
                            $(this).parents(".custom-select").find("select").val($(this).attr("value")).trigger("change");

                            e.stopPropagation();
                        },
                    },
                }).appendTo(allItems).text(optionText);

                if (that.attr("selected")) {
                    item.addClass("selected");
                }

                i++;
            });

            // Selected first item
            if (!isSelected(allItems.find(".item"))) {
                if (allItems.find(".item").first().attr("value") === "" && requiredSelect !== undefined) {
                    allItems.parents(".custom-select").find(".selected-item").addClass("parsley-error");
                }
                allItems.find(".item").first().addClass("selected");
            }
        });

        // Action when click selected box
        var selectedItem = $(".selected-item.enable"),
            allItems = $(".all-items");

        selectedItem.off("click").on("click", function (e) {
            var currentSelectedItem = $(this),
                currentAllItems = currentSelectedItem.next(".all-items");
            allItems.not(currentAllItems).addClass("all-items-hide");
            selectedItem.not(currentSelectedItem).removeClass("arrowanim");

            currentAllItems.toggleClass("all-items-hide");
            currentSelectedItem.toggleClass("arrowanim");

            e.stopPropagation();
        });

        $(document).on("click", function () {
            customSelect
                .find(".all-items")
                .addClass("all-items-hide");
            customSelect
                .find(".selected-item")
                .removeClass("arrowanim");
        });
    }
    customStyleSelect();

    // Custom tabindex form
    $(document).on("keyup", "select, input, .all-items, a, button, .upload-img", function (e) {
        e.preventDefault();
        let customSelect = $(".custom-select, .custom-select-special")
        if (e.which == 9) {
            if ($(e.target).is("select")) {
                $(e.target).siblings(".selected-item.enable").trigger('click');
                $(e.target).trigger("focus")
            } else if ($(e.target).is(".all-items .item") === false) {
                customSelect
                    .find(".all-items")
                    .addClass("all-items-hide");
                customSelect
                    .find(".selected-item")
                    .removeClass("arrowanim");
            }
        }
        if (e.which == 13) {
            if ($(e.target).parent().is(".all-items")) {
                $(e.target).siblings("select").trigger("focus")
            }
            $(e.target).trigger('click')
        }
    });
    $(window).scroll(function () {
        let height = $('.g-header').height();
        if ($(this).scrollTop() > height) {
            $('.header-mobile').addClass('sticky-container');
        } else {
            $('.header-mobile').removeClass('sticky-container');
        }
        if ($(this).scrollTop() > 0) {
            $('.g-header').addClass('sticky');
        } else {
            $('.g-header').removeClass('sticky');
        }
    });

    $(".close-btn").click(function () {
        $(this).parent().hide(500);
    });

    $(".nav-icon,.close__menu-mobile").click(function () {
        $("body").toggleClass("open-menu");
        $("header").toggleClass("hidden");
        $("#menu-mobile").toggleClass("hidden");
    });

    $(".search-button, .close-container").click(function () {
        $(".search-content").toggleClass("hidden");
        $(".error-page").toggleClass("hidden");
        $("body").toggleClass("open-menu");
        $('html, body').scrollTop(0);
        if ($(window).width() <= 768) {
            $("#content").toggleClass("position-static");
            $("header").toggleClass("hidden");
        }
    });

    $(".login__button").click(function () {
        $(".container__login-box").slideToggle(200);
        $(".container__forget-box").slideUp(200);
    });

    $(".btn-reset").click(function () {
        $(".container__forget-box").slideToggle(200);
        $(".container__login-box").slideUp(200);
    });

    $(".login__button-mobile,.close__login-mobile").click(function () {
        $(".login-box-mobile").slideToggle(200);
    });
    $(".login-box-mobile .btn-reset,.close__forget-password-mobile").click(function () {
        $(".forget-password--box-mobile ").slideToggle(200);
        $(".login-box-mobile").slideToggle(200);
    });

    $(".list-view--type,.tile-view--type").click(function () {
        $(".view--type .active").removeClass("active");
        $(this).addClass("active");
    });
    // $(".tile-view--type").click(function () {
    //     $("#result-view").removeClass("result__list-view");
    //     $("#result-view").addClass("result__tile-view");
    // });
    // $(".list-view--type").click(function () {
    //     $("#result-view").removeClass("result__tile-view");
    //     $("#result-view").addClass("result__list-view");
    // });
    //popup news
    function setCookie(name, value, hours) {
        var expires = "";
        if (hours) {
            var date = new Date();
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var cookieName = name + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var cookieArray = decodedCookie.split(";");

        for (var i = 0; i < cookieArray.length; i++) {
            var cookie = cookieArray[i];
            while (cookie.charAt(0) === " ") {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(cookieName) === 0) {
                return cookie.substring(cookieName.length, cookie.length);
            }
        }

        return null;
    }

    // This make the body scrollable when popup apppear
    if (!$.modal.isActive()) ;
    $('body').css('overflow', '');

    var dialogShown = getCookie('dialogShown');
    if (!dialogShown) {
        $('#popup--news').on($.modal.BEFORE_CLOSE, function (event, modal) {
            setCookie('dialogShown', 1, 24);
            $('body').css("overflow", "auto");
        });
        $('#popup--news').on($.modal.BEFORE_OPEN, function (event, modal) {
            $('body').css("overflow", "hidden");
        })
        $('#popup--news').modal({
            escapeClose: false,
            clickClose: false,
            blockerClass: "popup-news-modal jquery-modal",
        });
    } else {
        $('#popup--news').css('display', 'none');
    }
    // $('#popup--news').modal('show'); // this code use for popup development, delete if unnecessary.


    // $(".dropdown--text").click(function () {
    //     $(".dropdown--list").toggleClass("disabled");
    //     $(this).toggleClass("arrowanim");
    // });

    $(".dropdown--list--item a").click(function () {
        $(this).closest(".dropdown--list").toggleClass("disabled");
        $(this).closest(".dropdown--list").find('.dropdown--list--item').removeClass("selected");
        $(this).closest(".dropdown--list--item").addClass("selected");
        $(this).closest(".dropdown--box").find('.dropdown--text').removeClass("arrowanim")
        let value = $(this).closest(".dropdown--list--item").text();
        $(this).closest(".dropdown--box").find('.dropdown--text').text(value);
    });

    if ($(".homepage").hasClass("error-page")) {
        $("html").addClass("full-size-bg");
        $(".g-footer__acknowledge").addClass("hidden");
        $("footer").addClass("hidden");
    }

    $(".nav-item-mobile--child").click(function () {
        $(this).closest(".navbar-box").find("ul").slideToggle();
        $(this).toggleClass("arrowanim");
    })

    $(".membership .paragraph-content a").attr("target","_blank");
});

$(document).on('click', function (e) {
    if ($(e.target).closest(".login__button").length === 0 && $(e.target).closest(".container__login-box").length === 0) {
        $(".container__login-box").slideUp(200);
    }

    if ($(e.target).closest(".login__button").length === 0 && $(e.target).closest(".btn-reset").length === 0 && $(e.target).closest(".container__forget-box").length === 0 && $(e.target).closest(".container__login-box").length === 0) {
        $(".container__login-box").slideUp(200);
        $(".container__forget-box").slideUp(200);
    }
});
